var search = {
    init: function(){

		// var rowheaderHeight = $('header').height(); // MVL
		// var headerHeight = $('.cntr_header').height(); // MVL
		// $('.search--collapsible-target').css('height',rowheaderHeight -30); // MVL
		// $('.search--collapsible-target').css('height',rowheaderHeight); // MVL
	
		// console.log(rowheaderHeight); // 180
	//		console.log(headerHeight); // 132
	
		// var mobTopNav = $('.navigations .row_navtop').outerHeight(); // MVL	
	//		$('.search--collapsible-target-mob').css('height',mobTopNav); // MVL
	
		var sp = document.querySelector('.search--collapsible-trigger');
		var searchbar = document.querySelector('.search--collapsible-target');
		var shclose = document.querySelector('.search--collapsible-target-close');
			var spMob = document.querySelector('.search--collapsible-trigger-mob');
			// var searchbarMob = document.querySelector('.search--collapsible-target-mob');
			// var shcloseMob = document.querySelector('.search-close-mob');
	
		function changeClass() {
			searchbar.classList.add('search--collapsible-target-visible');
			$('.search--collapsible-target-visible input').focus();
		}
		function closesearch() {
			searchbar.classList.remove('search--collapsible-target-visible');
		}
		function changeClassMob() {
			searchbar.classList.add('search--collapsible-target-visible');
			$('.c input').focus();
			// $('.search--collapsible-target-mob .search-visible-mob').css('top',0);
		}
			/*function closesearchMob() {
				searchbarMob.classList.remove('search-visible-mob');
				$('.search--collapsible-target-mob').css('top',-300);
			} */
	
		sp.addEventListener('click', changeClass);
		shclose.addEventListener('click', closesearch);
			spMob.addEventListener('click', changeClassMob);
			// shcloseMob.addEventListener('click', closesearchMob);
	
	
		// Zet cursor op focus bij uitklappen
		// $('.search--collapsible-trigger').click(function(){
		// $(".search--collapsible-trigger").on("click", function(){
		// 	$('.search--collapsible-target-visible input').focus();
		// });

    }
}

$(document).on("mouseup", function(e){
    var container = $(".search--collapsible-target");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0){
        container.removeClass('search--collapsible-target-visible');
    }
});
