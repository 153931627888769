var accordion = {
    init: function () {
        $( '.js-accordion' ).click( function ( e ) {
            e.preventDefault();
            let $this = $( this );
            if ( $this.next().hasClass( 'c-accordion-active' ) ) {
                $this.next().removeClass( 'c-accordion-active' );
                $this.removeClass( 'c-accordion-active' );
                $this.next().slideUp( 350 );
            } else {
                $( '.c-accordion__content' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__title' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__content' ).slideUp( 350 );
                $this.next().toggleClass( 'c-accordion-active' );
                $this.toggleClass( 'c-accordion-active' );
                $this.next().slideToggle( 350 );

                
                // $('body,html').animate({scrollTop:0},800); // when a tab is clicked, always show active tab on top of page :::
                // var mijnvar = $('.c-accordion__content.c-accordion-active').offset().top;
                // alert(mijnvar);
                // $('html,body').animate({ scrollTop: $this.offset().top}, 500); 
					
				// var offset = $('.c-accordion__content.c-accordion-active').offset();
				// if(offset) {
				// 	$('html,body').animate({ scrollTop: $('.c-accordion__content.c-accordion-active').siblings('.js-accordion').offset().top}, 500); 
				// }
            }

            // console.log($(this).offset().top);
            // mijnvar = 400;
            // alert(mijnvar);
            // $('body,html').animate({scrollTop:0},500); // when a tab is clicked, always show active tab on top of page :::
            // $('html,body').animate({scrollTop:mijnvar}, 500);
        } );
    }
}


		// Now, when a tab is clicked, always show active tab on top of page :::
/* 		$(function () {
			$('#myTabReportage-accordion').on('shown.bs.collapse', function (e) {
				var offset = $('.panel.panel-default > .panel-collapse.in').offset();
				if(offset) {
					$('html,body').animate({ scrollTop: $('#myTabReportage-accordion .panel-collapse.in').siblings('.panel-heading').offset().top}, 500); 
				}
			});
		}); */